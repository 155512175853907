.partnerreadiness {
  h2 {
    text-align: center;
  }

  .filters {
    margin-top: 2em;
    margin-bottom: 3em;
  }

  .filters .heading {
    text-transform: uppercase;
    font-size: 0.8em;
    color: #ffffff;
    font-weight: 100;
  }
  .filters .form-check label {
    font-size: 0.8em;
  }

  .category {
    color: #ff9900;
  }

  .topic {
    margin: 25px 0;
    color: #E3E3E3;

    h3 {
      text-decoration: underline;
    }
  }

  .product {
    margin: 10px 25px 20px;
    color: #E3E3E3;

    a{
      color:#c5a1ff;
    }
  }

  .resource {
    margin: 4px 0;
    color: #E3E3E3;
  }


}
