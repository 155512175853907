/* Basic */

body {
  background: #232f3e;
}

body.is-preload *,
body.is-preload *:before,
body.is-preload *:after {
  animation: none !important;
  transition: none !important;
}

#root p span {
  color: #e3e3e3 !important;
}

::-webkit-scrollbar {
  width: 3px;
  height: 3px;
}
::-webkit-scrollbar-thumb {
  background-image: linear-gradient(to bottom, #d45b07aa, #ffcc83aa, #d45b07aa);
}
::-webkit-scrollbar-track {
  background: none;
}

@font-face {
  font-family: "Ember";
  src: url("./static/fonts/AmazonEmber_W_Th.woff2") format("woff2");
  font-weight: 100;
}
@font-face {
  font-family: "Ember";
  src: url("./static/fonts/AmazonEmber_W_ThIt.woff2") format("woff2");
  font-weight: 100;
  font-style: italic;
}
@font-face {
  font-family: "Ember";
  src: url("./static/fonts/AmazonEmber_W_Lt.woff2") format("woff2");
  font-weight: 200;
}
@font-face {
  font-family: "Ember";
  src: url("./static/fonts/AmazonEmber_W_LtIt.woff2") format("woff2");
  font-weight: 200;
  font-style: italic;
}
@font-face {
  font-family: "Ember";
  src: url("./static/fonts/AmazonEmber_W_Rg.woff2") format("woff2");
  font-weight: 400;
}
@font-face {
  font-family: "Ember";
  src: url("./static/fonts/AmazonEmber_W_RgIt.woff2") format("woff2");
  font-weight: 400;
  font-style: italic;
}
@font-face {
  font-family: "Ember";
  src: url("./static/fonts/AmazonEmber_W_SBd.woff2") format("woff2");
  font-weight: 500;
}
@font-face {
  font-family: "Ember";
  src: url("./static/fonts/AmazonEmber_W_SBdIt.woff2") format("woff2");
  font-weight: 500;
  font-style: italic;
}
@font-face {
  font-family: "Ember";
  src: url("./static/fonts/AmazonEmber_W_Bd.woff2") format("woff2");
  font-weight: 600;
}
@font-face {
  font-family: "Ember";
  src: url("./static/fonts/AmazonEmber_W_BdIt.woff2") format("woff2");
  font-weight: 600;
  font-style: italic;
}

body {
  font-family: "Ember";
  font-weight: 300;
  color: #e3e3e3;

  background-attachment: fixed;
  background-image: url("./static/content.svg");
  background-position: top right;
  background-repeat: no-repeat;
  background-size: 100% 100%;
}

main a {
  transition: color 0.2s ease, border-bottom-color 0.2s ease;
  border-bottom: dotted 1px rgba(197, 161, 255, 0.3);
  color: #c5a1ff;
  text-decoration: none;
}

main a:hover {
  border-bottom: solid 1px rgba(197, 161, 255, 0.8);
  color: #c5a1ff;
  text-decoration: none;
}

.edit-quip {
  text-align: center;
  font-size: 12px;
  margin-top: 50px;
}

main {
  margin-left: 18em;
}

main > #content {
  padding-top: 50px;
}

.h1,
.h2,
.h3,
.h4,
.h5,
.h6,
h1,
h2,
h3,
h4,
h5,
h6 {
  font-weight: 400;
}

h1:first-of-type {
  display: none;
}

h1 {
  font-size: 2rem;
}

h2 {
  font-size: 1.5rem;
}

h3 {
  font-size: 1.25rem;
}

h4 {
  font-size: 1.1rem;
}

h5 {
  font-size: 0.8rem;
}

.container {
  width: 90%;
}

@media screen and (max-width: 1079px) {
  main {
    margin: 0;
    margin-top: 3.5em;
  }
}
