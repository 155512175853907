.workshops {
  em.date {
    vertical-align: middle;
  }

  .filters {
    margin-top: 2em;
    margin-bottom: 2em;
  }

  .filters .heading {
    text-transform: uppercase;
    font-size: 0.8em;
    color: #ffffff;
    font-weight: 100;
  }
  .filters .form-check label {
    font-size: 0.8em;
  }

  .badge {
    font-size: 0.7em;
    padding: 0.25em 0.5em;
  }

  .tags {
    margin: 0.2em 0;
  }

  /* Tables */
  div[data-section-style="8"] {
    margin: 12px 0;
  }

  table {
    color: #fff !important;
  }

  table th,
  table td {
    border-color: #37534d !important;
    border-right: 1px solid;
  }

  table th:last-child,
  td:last-child {
    border-right-style: none;
  }

  table th {
    background-color: #067f68 !important;
    font-size: small;
    border-top-style: none !important;
    border-bottom-style: none !important;
  }

  table td {
    font-size: small;
  }

  table td.bold {
    font-weight: bold;
  }

  table td.italic {
    font-style: italic;
  }

  table td.underline {
    text-decoration: underline;
  }

  table td.strikethrough {
    text-decoration: line-through;
  }

  table td.underline.strikethrough {
    text-decoration: underline line-through;
  }

  .table-dark td {
    background-color: #343a40 !important;
  }

  .table > :not(:first-child) {
    border-top: 0px;
  }
}
